@import '~antd/dist/antd.css';

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

html, body, #root {
  height: 100%
}
